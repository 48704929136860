<template>
  <v-container fluid class="d-flex flex-column align-center pt">
    <div class="text-h1">
      <div class="code-item">4</div>
      <div class="code-item item-zero">0</div>
      <div class="code-item">4</div>
    </div>
    <div class="text-subtitle-1">
      <slot>{{ $vuetify.lang.t(`$vuetify.notFoundLabel`) }}</slot>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
<style scoped>
.code-item {
  display: inline-flex;
}
.item-zero {
  padding-left: 5px;
  transform: rotate(35deg);
}
.pt {
  padding-top: 20%;
}
</style>